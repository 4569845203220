.user-card {
  position: relative;
  z-index: z('default');
  display: flex;
  flex-direction: column;
  margin: 70px auto 0 auto;
  padding: 55px 0 38px 0;
  min-width: 243px;
  max-width: 245px;
  border: 4px solid #293547;
  border-radius: 30px;
  background-color: #fdfcee;

  .tag {
    position: absolute;
    top: -68px;
    left: calc(50% - 29px);
    display: block;
    height: 104px;
    width: 60px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      background: url('../../images/male_tag_01@2x.png') center center/contain
        no-repeat;
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(100% - 17px);
      left: calc(50% - 44px);
      display: block;
      height: 16px;
      width: 88px;
      border: 4px solid #293547;
      border-radius: 6px;
      background-color: #010c20;
    }
  }

  .thumb {
    display: block;
    margin: 0 auto;
    height: 170px;
    width: 170px;
  }

  .nickname {
    display: block;
    margin-top: 23px;
    @include font(38, 700, #232323, 55);
    text-align: center;
    letter-spacing: -0.02em;
  }

  .desc {
    display: block;
    margin-top: 15px;
    @include font(18, 700, #232323, 26);
    text-align: center;
    letter-spacing: -0.02em;
  }

  &[data-gender='female'] {
    .tag {
      &::after {
        background-image: url('../../images/female_tag_01@2x.png');
      }
    }
  }
}
