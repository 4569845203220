body {
  user-select: none;
  padding-bottom: calc(constant(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom));
  background-color: #010c20;
}

.layout-wrap {
  @include vh-100;
  display: flex;
  flex-direction: column;

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
}

.layout-contents-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  > form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .contents-container {
    flex: 1;
    overflow: auto;
    display: block;
    padding: 0 20px;

    &[data-fit='true'] {
      padding-right: 0;
      padding-left: 0px;
    }
  }
}
