.circles {
  display: block;
  height: 136px;
  width: 136px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 4px;
    width: 4px;
    background: inline-svg('icon-circle-01', $primary) center/contain no-repeat;
    transform: translate(-50%, -50%);
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 0.5px);
    left: 50%;
    display: block;
    height: 1px;
    width: 200px;
    background-color: $primary;
    transform: rotate(45deg);
    transform-origin: 0px 0px;
  }

  @keyframes rotate-circle {
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
    // @for $i from 0 to 10 {
    //   #{$i * 10}% {
    //     transform: translate(-50%, -50%) rotate($i * 36deg);
    //   }
    // }
  }

  @keyframes rotate-circle-revserse {
    to {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
    // @for $i from 0 to 10 {
    //   #{$i * 10}% {
    //     transform: translate(-50%, -50%) rotate(($i * -1) * 36deg);
    //   }
    // }
  }

  .arc {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 100%;
    width: 100%;
    border: 1px solid $primary;
    border-radius: 100%;
    transform: translate(-50%, -50%) rotate(0deg);

    &:nth-child(even) {
      animation: rotate-circle 10s linear infinite;
    }
    &:nth-child(odd) {
      animation: rotate-circle-revserse 10s linear infinite;
    }

    &:nth-child(1) {
      border-top: 1px solid transparent;
    }

    &:nth-child(2) {
      height: 88.2352941176471%;
      width: 88.2352941176471%;
      border-bottom: 1px solid transparent;
    }

    &:nth-child(3) {
      height: 74.2647058823529%;
      width: 74.2647058823529%;
      border-right: 1px solid transparent;
    }

    &:nth-child(4) {
      height: 57.3529411764706%;
      width: 57.3529411764706%;
      border-left: 1px solid transparent;
    }
  }

  &[data-direction='none'] {
    &::before,
    &::after {
      content: none;
    }
  }

  &[data-direction='right-bottom'] {
  }

  &[data-direction='left-top'] {
    &::after {
      right: 50%;
      left: auto;
      transform-origin: 200px 0px;
    }
  }
}
