.remain-counter {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 36px;

  .timer {
    display: block;
    padding: 0 20px 2px 10px;
    @include font(14, 400, #3e3e3e, 20);
    border-radius: 22px;
    background-color: #ededed;
  }

  .remain {
    position: absolute;
    top: 0;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    height: 36px;
    width: 36px;
    @include font(22, 700, #ffffff, 20);
    border: 3px solid #cf145e;
    border-radius: 100%;
    background-color: #ff0066;
  }
}
