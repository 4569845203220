/* variable */
$font-base-stack: ('Noto Sans KR', system-ui, -apple-system, sans-serif);
$font-special-stack: ('Black Han Sans', system-ui, -apple-system, sans-serif);
$standard-easing: cubic-bezier(0.4, 0, 0.2, 1);
$defaultDuration: (0.3s);
$defaultTransition: ($defaultDuration $standard-easing);

$break-min: (320px);
$break-tablet: (768px);
$break-desktop: (1280px);
$max-desktop: (1770px);
$min-desktop: (1364px);

$fixed-max-contents: (1180px);

$font-size: (100%);
$default: (#212121);
$primary: (#cc33cc);

