.page-chat {
  background-color: #C5D5D9;

  .navigation-header {
    position: fixed;
  }

  .chat-container {
    overflow: auto;
    overflow-x: hidden;
    display: block;
    padding: 15px 15px 55px 15px;
  }

  .date {
    display: block;
    @include font(16, 400, #464646, 20);
    text-align: center;
  }

  .list-messages {
    display: flex;
    flex-direction: column;
  }

  .message-item {
    display: flex;
    flex-direction: column;
    margin-top: 6px;

    &[data-start='true'] {
      margin-top: 15px;

      .message {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
    }
    &[data-end='true'] {
      .message {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .date { display: block; }
      }
    }

    > dt {
      display: flex;
      align-items: center;
      gap: 7px;

      .profile {
        overflow: hidden;
        display: block;
        height: 46px; width: 46px;
        border-radius: 100%;
        img {
          display: block;
          height: 100%; width: 100%;
        }
      }
      .nickname {
        display: block;
        @include font(14, 400, #464646, 20);
      }
    }

    > dd {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 0 0 53px;
    }

    .message {
      position: relative;
      display: block;
      padding: 6px 13px;
      max-width: 80%;
      @include font(14, 400, #464646, 20);
      border-radius: 6px;
      background-color: #fff;


      .date {
        position: absolute;
        bottom: 2px; left: calc(100% + 5px);
        display: none;
        @include font(12, 400, #464646, 16);
      }
    }

    // 상대방
    &[data-me='false'] {
      + [data-me='false'] {
        > dt { display: none; }
      }
    }

    // 나일 경우
    &[data-me='true'] {
      > dt {
        display: none;
      }
      > dd {
        align-items: flex-end;
        
        .message {
          background-color: #E4E422;

          .date {
            right: calc(100% + 5px);
            left: auto;
          }
        }
      }
    }
  }
  
  .write-container {
    position: absolute; right: 0; bottom: 0; left: 0;
    

    .form-write {
      position: fixed;
      right: 0; bottom: 0; left: 0;
      display: block;
      padding: 3px 10px;
      background-color: #F6F6F6;

      .control-item {
        display: flex;
        gap: 8px;
        
        input[type='text'] {
          flex: 1;
          text-align: left;
          border-radius: 34px;
        }
      }

      .btn-send {
        overflow: hidden;
        display: block;
        height: 34px; width: 34px;
        text-indent: -9999px;
        border-radius: 100%;
        background: #e4e422 inline-svg('icon-up-01', #464646) center 40%/60% no-repeat;

        &[disabled] {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
  }
}

