.ui-select-control {
  .hc__control {
    min-height: 32px;
    padding: 0;
    border: 1px solid #ececec;
    border-radius: 0;
    background-color: #fff;
    box-shadow: unset;

    &:hover {
      cursor: pointer;
      border-color: #9696a0;
    }

    .hc__value-container {
      overflow: visible;
      flex-wrap: nowrap;
      padding: 6px 10px;
      font-size: 0em;

      .hc__placeholder {
        position: static;
        margin: 0;
        @include font(14, 400, #d6d6d6, 20);
        text-align: right;
        transform: none;
      }

      .hc__single-value,
      .hc__multi-value {
        position: static;
        grid-area: 1/1/2/3;
        max-width: 100%;
        @include font(14, 400, #cf145e, 20);
        text-align: right;
        transform: unset;
      }
    }

    .hc__indicators {
      display: none;
    }

    // .hc__control
    &--is-focused {
      border-color: $primary;
    }

    &--menu-is-open {
      border-color: $primary;
      // box-shadow: 0px 0px 4px rgba(8, 73, 217, 0.3);

      &:hover {
        border-color: $primary;
      }
    }
  }
}
