.ui-switch-control {
  position: relative;
  display: inline-flex;
  min-width: 52px;

  > input {
    position: absolute;
    left: -9999px;

    &:checked,
    &[checked='true'] {
      + .switch {
        background-color: #34C759;

        &:active {
          .thumb {
            transform: translateX(calc(100% - 4px)) scale(1.15, 0.85);
          }
        }

        .thumb {
          transform: translateX(calc(100% - 4px));
        }
      }
    }
  }

  > .switch {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: 30px;
    width: 100%;
    border-radius: 30px;
    background-color: #C9C9C9;
    @include transition(background-color $defaultTransition);

    &:active {
      .thumb {
        transform: scale(1.15, 0.85);
      }
    }

    .thumb {
      position: absolute;
      top: 2px;
      left: 2px;
      z-index: z('default');
      display: block;
      height: 26px;
      width: 26px;
      border-radius: 100%;
      background-color: #fff;
      filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.059));
      @include transition(transform $defaultTransition);
    }
  }
}
