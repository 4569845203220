body.ReactModal__Body--open {
  overflow: hidden;
  min-height: -webkit-fill-available;
  max-height: 100vh;
}

.ReactModal {
  &__Overlay {
    z-index: z('modal');
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: #fff !important;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    @include transition(
      opacity 0.3s $standard-easing,
      transform 0.3s $standard-easing
    );
  }

  &__Overlay--after-open {
    opacity: 1;
    transform: translateY(0);

    &.side-to-left {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__Overlay--before-close {
    opacity: 0;
    transform: translateY(100%);

    &.side-to-left {
      transform: translateX(-100%);
    }
  }

  &__Content {
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    padding: 0px 0px 0px 0px !important;
    border: 0px !important;
    border-radius: 0px !important;

    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .layout-contents-container {
      flex: 1;
      min-height: -webkit-fill-available;
      height: auto;
      overflow: scroll;
      overflow-x: hidden;
      background-color: #fff;
    }
  }
}
