.page-waiting {
  position: relative;
  overflow-x: hidden;
  max-width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  @keyframes drift {
    0% {
      transform: translateY(5%) rotate(0deg);
    }
    50% {
      transform: translateY(-25%) rotate(1080deg);
    }
    100% {
      transform: translateY(5%) rotate(0deg);
    }
  }

  .mask-loader {
    overflow: hidden;
    position: relative;
    display: block;
    height: 51px;
    width: 178px;
    text-indent: -9999px;
    background-color: $primary;
    mask: url('../../svgs/oneinmil_bi_01.svg');
    mask-size: auto 100%;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -50%;
      left: -25%;
      display: block;
      height: 300px;
      width: 300px;
      border-radius: 45%;
      background-color: #ff0;
      animation: drift 24s ease-in-out infinite;
    }
  }

  .slogan {
    display: block;
    margin-top: 13px;
    @include font(20, 400, #e2e2e2, 30);
  }

  .btn-close {
    overflow: hidden;
    display: block;
    margin-top: 20px;
    height: 30px;
    width: 30px;
    text-indent: -9999px;
    background-image: inline-svg('icon-close-01', #e2e2e2);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .circles {
    position: absolute;
    top: 73px;
    left: 73px;
    z-index: 0;
  }

}