.ui-buttons {
  overflow: hidden;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 13px 0px;
  background-color: #707070;

  > .txt {
    @include font(25, 700, #ffffff, 37);
  }

  &[data-disabled='true'] {
    opacity: 0.5;
  }

  &[data-intent='confirm'] {
    background-color: #e4e422;
    > .txt {
      color: $default;
    }
  }

  &[data-intent='primary'] {
    background-color: #CC33CC;
    > .txt {
      color: #fff;
    }
  }}
