@import './toastify/main';

.fadeIn {
  animation: fadeIn 0.3s;
}
.fadeOut {
  animation: fadeOut 0.3s;
}

.zoomIn {
  animation: zoomIn 0.3s;
}
.zoomOut {
  animation: zoomOut 0.3s;
}

.animated {
  animation-duration: 0.3s !important;
  transition-duration: 0.3s !important;
  animation-iteration-count: 1 !important;
}

.Toastify {
  &__toast-container {
    top: 50%;
    padding: 10px;
    display: block;
    transform: translateY(-50%);
  }

  &__toast {
    margin: 0px;
    padding: 10px 20px;
    min-height: auto;
    border: 0px;
    border-radius: 5px;
    background-color: rgba(#5a6367, 1);

    &--error {
      background-color: #c51d25;
    }
  }

  &__toast-body {
    margin: 0;
    padding: 0;
    width: 100%;
    @include font(14, 700, #fff, 20);
    text-align: center;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;

      > .actions {
        > * + * {
          margin-left: 3px;
        }
      }
    }

    .toast-confirm {
      flex: 1;
      padding: 10px 0;
      .message {
        display: block;
        @include font(14, 700, #fff, 20);
      }

      > .actions {
        display: flex;
        margin-top: 10px;
        > * {
          flex: 1;
        }
      }
    }
  }

  &__toast-icon {
    display: none;
  }

  &__close-button {
    display: none;
  }
}
