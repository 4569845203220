@import './select-answer';
@import './select-control';
@import './select-profile';
@import './switch-control';

.form-item {
  display: block;

  + .form-item {
    margin-top: 10px;
  }

  .form-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    > * + * {
      margin-left: 10px;
    }
    > .ui-control {
      flex: 1;
    }
  }

  .invalid-message {
    display: block;
    margin-top: 5px;
    width: 100%;
    @include font(14, 400, #cf145e, 20);
  }
}

.form-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @include font(14, 400, $default, 20);
}

input[type='text'],
input[type='number'] {
  display: block;
  padding: 6px 10px;
  @include font(14, 400, #cf145e, 20);
  text-align: right;
  border: 1px solid #ececec;
  background-color: #fff;

  @include placeholder {
    color: #d6d6d6;
  }

  &:focus {
    border-color: $primary;
  }
}

select {
  appearance: none;
  display: block;
  padding: 6px 10px;
  width: 100%;
  @include font(14, 400, #cf145e, 20);
  text-align: right;
  border: 1px solid #ececec;
  background-color: #fff;
  direction: rtl;

  &:focus {
    border-color: $primary;
  }
}
