.page-splash {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 160px 18px 13px 18px;
  background-color: #010c20;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 1px;
    width: 162px;
    background-color: $primary;
    transform: rotate(45deg);
    transform-origin: 0px 0px;
  }

  .header {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 3px;

    .swiper-slogal {
      margin-left: unset;
      max-width: 300px;
      width: 100%;

      .slogan-item {
        opacity: 0 !important;
        max-width: 300px;
        @include font(55, 700, #fbfbfb, 75);
        letter-spacing: -0.05em;

        &.swiper-slide-active {
          opacity: 1 !important;
        }
      }
    }

    > .title {
      overflow: hidden;
      display: block;
      margin-top: 45px;
      height: 43px;
      width: 170px;
      text-indent: -9999px;
      background: inline-svg('oneinmil-bi-01', $primary) center center/contain
        no-repeat;
    }
  }

  .circles {
    position: absolute;
    bottom: calc(100% + 80px);
    right: 56px;
    height: 136px;
    width: 136px;
  }

  .actions {
    position: relative;
    display: block;

    .btn-oauth {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      width: 100%;
      @include font(15, 400, #010c20, 21);
      background-color: #ffffff;

      &::before {
        content: '';
        display: block;
        margin-right: 20px;
        height: 28px;
        width: 28px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      + .btn-oauth {
        margin-top: 11px;
      }

      &.google {
        &::before {
          background-image: inline-svg('icon-google-01', #000000);
        }
      }
      &.apple {
        &::before {
          margin-top: -2px;
          background-image: inline-svg('icon-apple-01', #000000);
        }
      }
      &.facebook {
        &::before {
          background-image: inline-svg('icon-facebook-01', #000000);
        }
      }
    }

    .legal-message {
      display: block;
      margin-top: 13px;
      @include font(10, 300, #b2b2b2, 15);

      button {
        font-size: inherit;
        color: #de75ad;
        line-height: inherit;
      }
    }
  }
}
