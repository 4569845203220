.select-answer {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  display: block;

  > .inner {
    display: flex;
    flex-direction: column;
    height: 100%;

    .answer-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #00040A;

      &:first-child {
        padding-bottom: 50px;
      }

      &:last-child {
        padding-top: 50px;
      }

      input[type='radio'] {
        opacity: 0;
        visibility: hidden;
        position: absolute;

        + label {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          @include font(60, 700, #E0E0E0, 90);
          text-align: center;
          // @include transition(background-color 0s);

          &[data-selected='false'] {
            color: #313131;
          }
        }

        &:checked {
          + label {
            background-color: #CF145E;
            // @include transition(background-color 0s);
          }
        }
      }
    }
  }
}