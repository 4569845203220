.navigation-header {
  position: relative;
  z-index: z('gnb');
  display: block;
  padding: 40px 42px 0px;
  min-height: 50px;

  > .navigation-title {
    display: block;
    padding-bottom: 12px;
    @include font(20, 500, #212121, 29);
    text-align: center;
    border-bottom: 1px solid #dadada;
  }

  > .actions {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  > .counter-actions {
    position: absolute;
    top: 14px;
    right: 34px;
  }

  > .step-actions {
    position: absolute;
    top: 14px; right: 18px;
    display: block;
    @include font(14, 400, #E5E5E5, 30);
  }

  .btn-navigation {
    overflow: hidden;
    display: block;
    height: 30px;
    width: 30px;
    text-indent: -9999px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    &[data-icon='close'] {
      background-image: inline-svg('icon-close-01', #ededed);
    }

    &[data-icon='setting'] {
      background-image: inline-svg('icon-setting-01', #ededed);
    }

    &[data-icon='back'] {
      background-image: inline-svg('icon-arrow-01', #222222);
      background-size: 40%;
    }
  }
}

.navigation-submit-actions {
  display: flex;
  
  > * {
    flex: 1;
  }
}
