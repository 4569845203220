body.ReactModal__Body--open {
  overflow: hidden;
  max-height: 100vh;
}

.ReactModal {
  &__Overlay {
    z-index: z('modal');
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: rgba(#000, 0.4) !important;
    opacity: 0;
    @include transition(opacity 0.3s $standard-easing);
  }

  &__Overlay--after-open {
    opacity: 1;
  }

  &__Overlay--before-close {
    opacity: 0;
  }

  &__Content {
    inset: 0px !important;
    padding: 0px !important;
    border: 0px !important;
    border-radius: 0px !important;

    .btn-close {
      overflow: hidden;
      display: block;
      height: 24px;
      width: 24px;
      text-indent: -9999px;
      background: inline-svg('icon-close-01', #212529) center center/contain
        no-repeat;
    }
  }
}
