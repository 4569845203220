@mixin transform {
  transform: translate3d(0, 0, 0);
}

@keyframes #{$rt-namespace}__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes #{$rt-namespace}__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes #{$rt-namespace}__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes #{$rt-namespace}__slideInDown {
  from {
    transform: translate3d(0, -200%, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes #{$rt-namespace}__slideOutRight {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes #{$rt-namespace}__slideOutLeft {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes #{$rt-namespace}__slideOutDown {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes #{$rt-namespace}__slideOutUp {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.#{$rt-namespace}__slide-enter {
  &--top-left,
  &--bottom-left {
    animation-name: #{$rt-namespace}__slideInLeft;
  }
  &--top-right,
  &--bottom-right {
    animation-name: #{$rt-namespace}__slideInRight;
  }
  &--top-center {
    animation-name: #{$rt-namespace}__slideInDown;
  }
  &--bottom-center {
    animation-name: #{$rt-namespace}__slideInUp;
  }
}

.#{$rt-namespace}__slide-exit {
  &--top-left,
  &--bottom-left {
    animation-name: #{$rt-namespace}__slideOutLeft;
  }
  &--top-right,
  &--bottom-right {
    animation-name: #{$rt-namespace}__slideOutRight;
  }
  &--top-center {
    animation-name: #{$rt-namespace}__slideOutUp;
  }
  &--bottom-center {
    animation-name: #{$rt-namespace}__slideOutDown;
  }
}
