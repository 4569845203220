// Alert 팝업
.ui-alert {
  .ReactModal {
    &__Overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      opacity: 1;
      background-color: rgba(#000, 0.4) !important;
      transform: none;
      @include transition(none);
    }

    &__Overlay--after-open {
      opacity: 1;
      transform: none;
    }

    &__Overlay--before-close {
      opacity: 0;
      transform: none;
    }

    &__Content {
      position: static !important;
      overflow: hidden !important;
      flex: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 300px;
      max-width: 300px;
      width: 300px;
      inset: 0px !important;
      padding: 0px 0px 0px 0px !important;
      border: 0px !important;
      border-radius: 0px !important;
      background-color: transparent !important;
      box-shadow: none;
    }
  }

  .alert-contents {
    display: block;
    padding: 40px 28px;
    @include font(13, 400, #121212, 19);
    text-align: center;
    background-color: #fff;
  }

  .alert-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    background-color: #fff;

    > button {
      flex: 1;
      padding: 19px 0;
      @include font(18, 500, #fff, 26);
      text-align: center;
      background-color: #B1B1B1;

      &.confirm {
        background-color: $primary;
      }
    }
  }
}
