.select-profile {
  .btn-profile {
    position: relative;
    display: block;
    padding: 5px 20px;
    width: 100%;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    background-color: #fff;

    img {
      display: block;
      height: 46px;
      width: 46px;
    }

    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 15px);
      right: 10px;
      display: block;
      height: 30px;
      width: 30px;
      background: inline-svg('icon-arrow-01', #696969) center center/30%
        no-repeat;
      transform: rotateY(180deg);
    }
  }
}

.expand-select-profile {
  position: fixed;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: z('modal');
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 82px;
  padding-bottom: 63px;
  background-color: #fff;

  > .navigation-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: z('default');
    padding-right: 0px;
    padding-left: 0px;
    background-color: #fff;
  }

  > .navigation-submit-actions {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: z('default') + 1;
  }

  .list-profile {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    background-color: #fff;

    > li {
      position: relative;
      display: block;
      width: calc(100% / 4);
      border-right: 1px solid #bdbdbd;
      border-bottom: 1px solid #bdbdbd;

      &:nth-child(4n + 4) {
        border-right: 0px;
      }
    }

    @keyframes selected-profile {
      0% {
        transform: rotateY(0deg) scale(1);
      }
      50% {
        transform: rotateY(180deg) scale(1.25);
      }
      100% {
        transform: rotateY(360deg) scale(1);
      }
    }

    .profile-item {
      display: block;
      padding: 8px 15px;
      height: 100%;
      width: 100%;
      @include transition(background-color 0.5s);

      img {
        display: block;
        height: 100%;
        width: 100%;
      }

      &[data-selected='true'] {
        background-color: #e4e422;
        img {
          animation: selected-profile 1s;
        }
      }
    }
  }
}
